import { Flex } from '../Flex';
import styled, { css } from 'styled-components';
import React, { Fragment, useMemo } from 'react';
import { useSlideContext } from '../../hooks/useSlideContext';
import { checkIcon } from '../../../../images/check.svg';

export interface ISlideCounter {
  active?: boolean;
  slideCount: number;
  index: number;
  currentSlideIndex: number;
}

interface ISlideCounterProgressBar {
  slideCount: number;
  index: number;
  currentSlideIndex: number;
}

const SlideCounterProgressBarWrapper = styled.div`
  width: 2.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  &:last-of-type {
    display: none;
  }
`;

const SlideCounterProgressBar = styled.div<ISlideCounterProgressBar>`
  width: 100%;
  height: 0.45rem;
  background: #607a54;
  transition: all 0.5s ease-out;
  box-shadow: inset 0 0 0 0 #f4f4f4;
  ${(props) =>
    props.index >= props.currentSlideIndex &&
    css`
      box-shadow: inset -100px 0 0 0 #f4f4f4;
    `};
`;

const SlideCounterItem = styled.div<ISlideCounter>`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  cursor: pointer;

  ::after {
    content: '${(props) =>
      props.index <= props.currentSlideIndex
        ? '✔'
        : css`
            ${(props) => props.index + 1}
          `}';
  }

  ${(props) =>
    props.active
      ? css`
          border: solid 1px #223a2e;
          background: #223a2e;
        `
      : css`
          border: solid 1px #fff;
        `};
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  transition: all 0.2s ease-in-out;
`;

export function SlideCounter() {
  const { currentSlideIndex, slideCount, slideTo } = useSlideContext();

  const slideCounterItems = useMemo(() => {
    const items = [];
    for (let i = 0; i < slideCount; i++) {
      items.push(
        <Fragment key={i}>
          <SlideCounterItem
            key={i}
            active={i === currentSlideIndex}
            index={i}
            slideCount={slideCount}
            currentSlideIndex={currentSlideIndex}
            onClick={() => slideTo(i)}
          />

          <SlideCounterProgressBarWrapper key={`${i}-sliderbar-wrapper`}>
            <SlideCounterProgressBar
              key={`${i}-sliderbar`}
              index={i}
              slideCount={slideCount}
              currentSlideIndex={currentSlideIndex}
            />
          </SlideCounterProgressBarWrapper>
        </Fragment>,
      );
    }
    return items;
  }, [currentSlideIndex, slideCount]);

  return <Flex>{slideCounterItems}</Flex>;
}
