import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import '../organism/garden-explorer/animations.css';

const islandPath = '../../images/explorers/island.svg';
const banksiaPath = '../../images/explorers/banksia.svg';
const beeFlippedPath = '../../images/explorers/beeflipped.svg';
const fencePath = '../../images/explorers/fence.svg';

export default function LoadingAnimation() {
  return (
    <div
      className={`tw-mx-auto tw-flex tw-h-[80vh] tw-w-52 tw-flex-col tw-items-start tw-justify-center tw-gap-6 tw-shadow-sm tw-transition-all tw-duration-300 lg:tw-h-[60vh]`}
    >
      <div className="tw-relative tw-h-40 tw-w-48 tw-transition-opacity tw-duration-300">
        <div className=" tw-z-20 tw-w-24">
          <StaticImage
            loading="eager"
            placeholder="blurred"
            blurredOptions={{ width: 80 }}
            src={fencePath}
            quality={100}
            alt=""
            className="tw-absolute tw-top-[40%] tw-left-[30%] tw-z-20 tw-w-24"
          />
        </div>
        <div className=" tw-absolute tw-bottom-[40%] tw-left-[8%] tw-z-10 tw-flex">
          <StaticImage
            loading="eager"
            placeholder="blurred"
            blurredOptions={{ width: 80 }}
            src={beeFlippedPath}
            quality={100}
            alt=""
            className="floatAnimation -tw-mr-6 tw-w-24 tw-transform"
          />
          <StaticImage
            loading="eager"
            placeholder="blurred"
            blurredOptions={{ width: 80 }}
            src={banksiaPath}
            quality={100}
            alt=""
            className="bobAnimation tw-z-10"
          />
        </div>
        <StaticImage
          loading="eager"
          placeholder="blurred"
          blurredOptions={{ width: 80 }}
          quality={100}
          src={islandPath}
          alt=""
          className="tw-absolute tw-bottom-0 tw-w-64 "
        />
      </div>
      <div className="tw-w-full tw-rounded-lg tw-bg-anbg-mid-green tw-p-6 tw-drop-shadow-solid-yellow">
        <p className="tw-mx-auto tw-text-center tw-text-lg tw-text-anbg-yellow">
          Just a sec...
        </p>
      </div>
    </div>
  );
}
