import React, { createContext, useMemo, useState } from 'react';

export const LoaderContext = createContext({
  currentExperience: '',
  explorerText: '',
  explorerImage: '',
});

export function LoaderContextProvider(props) {
  const { children, experience, setShowPage } = props;
  const [currentExperience, setCurrentExperience] = useState(experience);
  const [explorerText, setExplorerText] = useState('');
  const [explorerImage, setExplorerImage] = useState('');

  const defaultValue = useMemo(() => {
    return {
      currentExperience,
      setCurrentExperience,
      explorerText,
      setExplorerText,
      explorerImage,
      setExplorerImage,
      setShowPage,
    };
  }, [currentExperience, explorerImage, explorerText, setShowPage]);

  return (
    <LoaderContext.Provider value={defaultValue}>
      {children}
    </LoaderContext.Provider>
  );
}
