import React, { Suspense, useRef } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { Flex } from '../Flex';
import { useSlideContext } from '../../hooks/useSlideContext';
import LoadingAnimation from '../../../atom/LoadingAnimation';

export default function Slider() {
  const nodeRef = useRef(null);
  const { currentSlide } = useSlideContext();

  return (
    <Flex
      flexGrow={1}
      flexDirection={'row'}
      flexShrink={1}
      flexStyles={{ height: '100%' }}
    >
      <SwitchTransition mode={'out-in'}>
        <CSSTransition
          key={location.pathname}
          nodeRef={nodeRef}
          timeout={0}
          classNames="page"
          unmountOnExit
        >
          {() => (
            <Suspense fallback={<LoadingAnimation />}>
              <div ref={nodeRef} className="page tw-w-full">
                {currentSlide}
              </div>
            </Suspense>
          )}
        </CSSTransition>
      </SwitchTransition>
    </Flex>
  );
}
