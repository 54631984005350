import { Flex } from '../Flex';

import { SlideCounter } from '../SlideCounter/SlideCounter';
import React from 'react';

export type IControlButtonProps = {
  label: string;
};

export function ControlLabel(props: IControlButtonProps) {
  return (
    <Flex
      alignItems={'center'}
      justifyContent={'center'}
      flexGrow={1}
      flexDirection={'column'}
      flexGap={'0.25rem'}
    >
      <SlideCounter />
    </Flex>
  );
}
