import React, { useContext, useState } from 'react';
import { graphql } from 'gatsby';
import TemplateDefault from './default';
import SEO from '../components/seo';
import GardenExplorer from '../components/organism/garden-explorer';
import * as Interactive from '../components/sections/interactive';
import ExperienceLoader from '../components/experience-loader/experience-loader';
import {
  LoaderContext,
  LoaderContextProvider,
} from '../components/experience-loader/experience-loader-context';

export default function TopicPage({ data }) {
  const openImage =
    data.nodePage.relationships.openingImage.relationships.field_media_image_4;
  const closeImage =
    data.nodePage.relationships.closingImage.relationships.field_media_image_4;
  const badge =
    data.nodePage.relationships.badge.relationships.field_media_image_6;

  const [showPage, setShowPage] = useState('intro');
  const { explorerText, explorerImage } = useContext(LoaderContext);

  return (
    <TemplateDefault title={data.nodePage.title}>
      <LoaderContextProvider setShowPage={setShowPage}>
        <main>
          {showPage === 'intro' && (
            <Interactive.Intro
              title={data.nodePage.title}
              summary={data.nodePage.body.processed}
              image={openImage?.publicUrl}
              handleClick={() => setShowPage('experience')}
            />
          )}
          {showPage === 'experience' && (
            <ExperienceLoader experience={data.nodePage.title} />
          )}
          {showPage === 'outro' && (
            <Interactive.Outro
              summary={data.nodePage.bottomtext.processed}
              image={closeImage?.publicUrl}
              badge={badge?.publicUrl}
            />
          )}
          {explorerText && (
            <GardenExplorer image={explorerImage} text={explorerText} />
          )}
        </main>
      </LoaderContextProvider>
    </TemplateDefault>
  );
}

export const query = graphql`
  query ($drupalId: String!) {
    nodePage: nodeAnbgLhExperience(drupal_id: { eq: $drupalId }) {
      title
      body {
        processed
      }
      bottomtext: field_anbg_lh_closing {
        processed
      }
      relationships {
        topic: field_anbg_lh_topics {
          ... on taxonomy_term__anbg_lh_topic {
            drupal_internal__tid
            name
          }
        }
        openingImage: field_anbg_lh_img {
          ... on media__anbg_lh_inline_img {
            relationships {
              field_media_image_4 {
                publicUrl
              }
            }
          }
        }
        closingImage: field_anbg_lh_lowerimg {
          ... on media__anbg_lh_inline_img {
            relationships {
              field_media_image_4 {
                publicUrl
              }
            }
          }
        }
        badge: field_anbg_lh_badge {
          ... on media__anbg_lh_badge {
            relationships {
              field_media_image_6 {
                publicUrl
              }
            }
          }
        }
      }
    }
  }
`;

export function Head({ data }) {
  return <SEO title={data.nodePage.title} />;
}
