import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import beeImage from '../../../images/explorers/bee-binoculars.svg';
import stars from '../../../images/stars.svg';
import desk from '../../../images/desk.svg';
import bgQuestion from '../../../images/bgQuestion.png';
import('./quiz-button-animation.css');
interface IQuizDetails {
  title: string;
  backgroundImage?: string;
  instructions: string;
  minimumCorrect: number;
  questions: Array<{
    question: string | ReactElement;
    image: any;
    data: {
      answers: Array<string>;
      correctAnswer: string;
    };
  }>;
  setDone: () => void;
}

export default function Quiz(props: IQuizDetails) {
  const {
    title,
    backgroundImage,
    instructions,
    minimumCorrect,
    questions,
    setDone,
  } = props;
  const [completed, setCompleted] = useState([]);
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [answerDialogue, setAnswerDialogue] = useState(' ');

  const quizQuestions = useMemo(() => {
    return questions;
  }, [questions]);

  useEffect(() => {
    setCompleted(Array(quizQuestions.length).fill(0));
  }, [quizQuestions.length]);
  const correctAnswers = completed.filter((value) => value === 1).length;
  const onSubmit = () => {
    // Check if all answers are correct, not just meeting the minimum.
    if (correctAnswers === quizQuestions.length) {
      setDone();
    }
  };

  const nextQuestion = (moveForward, answerResponse) => {
    setAnswerDialogue(answerResponse);
    setTimeout(() => {
      if (activeQuestion + 1 > quizQuestions.length - 1) {
        return;
      }
      moveForward && setActiveQuestion(activeQuestion + 1);
      setAnswerDialogue(' ');
    }, 900);
  };

  const dynamicAnswerClasses = useMemo(() => {
    return answerDialogue === ' '
      ? 'tw-translate-y-0 tw-text-opacity-0'
      : '-tw-translate-y-[80%] tw-text-opacity-100';
  }, [answerDialogue]);

  return (
    <section className="tw-relative tw-flex tw-w-full tw-flex-grow tw-justify-center tw-bg-anbg-orange tw-px-4 xl:tw-px-12">
      <div className="tw-absolute -tw-inset-16 ">
        <img
          src={backgroundImage ?? bgQuestion}
          alt=""
          className="-tw-inset-16 tw-h-full tw-w-full tw-object-cover"
        />
      </div>
      <div className="tw-max-w-auto tw-w-full tw-max-w-6xl tw-justify-between tw-gap-10 2xl:tw-flex 3xl:tw-max-w-7xl">
        <div className="2xl:tw-w-1/3 3xl:tw-w-1/2 ">
          <div className="tw-relative tw-mx-auto tw-flex tw-h-[350px] tw-w-[350px] tw-flex-col tw-items-center tw-justify-center tw-rounded-full tw-bg-anbg-yellow 2xl:tw-h-[400px] 2xl:tw-w-[400px] 3xl:tw-h-[550px] 3xl:tw-w-[550px] ">
            <h2
              className="textLarge tw-text-center tw-font-serif "
              dangerouslySetInnerHTML={{
                __html: title.replaceAll('|', '<br/>'),
              }}
            />
            <div className="tw-w-2/3 tw-pt-6 tw-text-center">
              {instructions}
            </div>
            <div className="tw-absolute tw-top-0 tw-right-0 tw-w-32">
              <img src={beeImage} alt="" className="tw-w-full" />
            </div>
            <div className="tw-absolute -tw-bottom-10 tw-left-1/2 tw-w-48 -tw-translate-x-1/2">
              <img src={stars} alt="" className="tw-w-full" />
            </div>
          </div>
        </div>
        <div className="tw-relative tw-z-10 tw-mx-auto tw-w-full 2xl:tw-w-2/3 3xl:tw-w-1/2">
          <div
            className={`tw-absolute tw-top-0 tw-left-1/3 tw-right-1/3 -tw-z-10 tw-min-h-[72px] tw-rounded-tl-lg tw-rounded-tr-lg tw-bg-transparent  tw-p-4 tw-pb-8  tw-transition-transform tw-duration-200 tw-ease-in-out ${dynamicAnswerClasses} , ${
              answerDialogue === `That's right!`
                ? '!tw-bg-anbg-dark-green tw-text-white'
                : '!tw-bg-anbg-orange tw-text-white'
            }`}
          >
            <p className={`tw-text-center`}>{answerDialogue}</p>
          </div>
          <Question
            question={quizQuestions[activeQuestion].question}
            image={quizQuestions[activeQuestion].image}
            questionNumber={activeQuestion + 1}
            //peoples.findIndex(p => p.attr1 == "john"
            data={quizQuestions[activeQuestion].data}
            completed={completed}
            setCompleted={setCompleted}
            activeQuestion={activeQuestion}
            nextQuestion={(moveForward, answerResponse) => {
              nextQuestion(moveForward, answerResponse);
            }}
          />
          <div className="tw-absolute tw-left-0 tw-top-1/2 tw-z-20 tw-mt-6 tw-flex tw-w-full -tw-translate-y-1/2 tw-items-center tw-justify-between tw-space-x-6 tw-bg-white tw-p-4">
            <div className="tw-flex tw-items-center tw-space-x-4">
              {completed.map((answer, index) => (
                <Pagination
                  key={index}
                  result={answer}
                  onClick={() => setActiveQuestion(index)}
                />
              ))}
            </div>
            <div className="tw-flex tw-items-center tw-space-x-4">
              <button
                type="button"
                className={`tw-rounded-2xl tw-px-6 tw-py-2 tw-uppercase tw-text-white ${
                  correctAnswers === quizQuestions.length // Updated condition
                    ? 'tw-bg-anbg-orange hover:tw-bg-anbg-dark-green hover:tw-text-white'
                    : 'tw-cursor-default tw-bg-gray-500'
                }`}
                onClick={() => onSubmit()}
                disabled={correctAnswers !== quizQuestions.length} // Button disabled property
              >
                Go
              </button>
            </div>
          </div>
          <img src={desk} alt="" className="tw-w-full" />
        </div>
      </div>
    </section>
  );
}

interface IQuestionProps {
  question: string;
  questionNumber: number;
  image: any;
  data: {
    answers: Array<string>;
    correctAnswer: string;
    correctMessage?: string;
    incorrectMessage?: string;
  };
  completed: Array<number>;
  setCompleted: any;
  activeQuestion: number;
  nextQuestion: any;
}

function Question({
  question,
  questionNumber,
  image,
  data,
  completed,
  setCompleted,
  activeQuestion,
  nextQuestion,
}: IQuestionProps) {
  const [randomAnswers, setRandomAnswers] = useState(data.answers);

  useEffect(() => {
    const { answers } = data;
    const newArray = answers;
    let currentIndex = answers.length;
    let randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      [answers[currentIndex], answers[randomIndex]] = [
        answers[randomIndex],
        answers[currentIndex],
      ];
    }

    setRandomAnswers(newArray);
  }, [data]);

  const onAnswer = (answer: string) => {
    let button = event.target;
    const newCompleted = completed.map((value, index) => {
      if (value === 1) {
        // Don't re-do question if the answer is already ccorrect
        return value;
      }

      if (index === activeQuestion) {
        return answer === data.correctAnswer ? 1 : -1;
      }
      return value;
    });

    setCompleted(newCompleted);
    const sayCorrect = data.correctMessage || "That's right!";
    const sayIncorrect = data.incorrectMessage || 'Try again!';
    if (newCompleted[activeQuestion] == 1) {
      nextQuestion(true, sayCorrect);
      return;
    }
    button.classList.add('shakeit');
    setTimeout(() => {
      button.classList.remove('shakeit');
    }, 700);

    nextQuestion(false, sayIncorrect);
  };

  return (
    <div className="tw-absolute tw-left-1/2 tw-top-10  tw-z-20 tw-flex tw-w-7/12 -tw-translate-x-1/2 tw-space-x-6 tw-text-white">
      <div>
        <div className="textSmall tw-font-bold">Question {questionNumber}:</div>
        <div
          className="tw-pt-2 tw-pb-5"
          dangerouslySetInnerHTML={{ __html: question }}
        />

        <div className="tw-grid tw-grid-cols-2 tw-gap-4">
          {randomAnswers.map((answer, answerIndex) => (
            <Answer
              key={answerIndex}
              text={answer}
              answeredCorrect={
                answer === data.correctAnswer ? completed[activeQuestion] : 0
              }
              onClick={() => onAnswer(answer)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

interface IAnswerProps {
  text: string;
  onClick: any;
  answeredCorrect: number;
}

function Answer({ text, onClick, answeredCorrect }: IAnswerProps) {
  return (
    <button
      type="button"
      className={`QuizOption tw-rounded-full tw-px-6 tw-py-2 ${
        answeredCorrect === 1
          ? 'tw-bg-white tw-text-anbg-dark-green'
          : 'tw-bg-anbg-dark-green'
      } hover:tw-bg-white hover:tw-text-anbg-dark-green`}
      onClick={onClick}
    >
      {text}
    </button>
  );
}

interface IPaginationProps {
  result: number;
  onClick: any;
}

function Pagination({ result, onClick }: IPaginationProps) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`${
        // eslint-disable-next-line no-nested-ternary
        result === 1
          ? 'tw-bg-anbg-dark-green'
          : result === -1
          ? 'tw-bg-anbg-red'
          : ''
      } tw-h-4 tw-w-4 tw-rounded-full tw-border  tw-border-anbg-dark-green`}
    />
  );
}
