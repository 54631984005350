import React, { lazy, useContext, useEffect } from 'react';
import { LoaderContext } from './experience-loader-context';
import { Framed } from '../FramedSlider';
import { Frame } from '../FramedSlider/components/Frames/Frame';
import Quiz from '../organism/quiz/quiz';
import img1 from '../../images/experience/lifecycles/quiz/q1.png';
import img2 from '../../images/experience/lifecycles/quiz/q2.png';

// Slides for Lifecycle module
const Lifecycles = lazy(() => import('../modules/lifecycle-slides/lifecycle'));
const Germination = lazy(
  () => import('../modules/lifecycle-slides/germination'),
);
const Pollination = lazy(
  () => import('../modules/lifecycle-slides/pollination'),
);
const Seeds = lazy(() => import('../modules/lifecycle-slides/seed-dispersal'));
const Triggers = lazy(() => import('../modules/lifecycle-slides/triggers'));
const Order = lazy(() => import('../modules/lifecycle-slides/order'));

// Slides for Pollination module
const Importance = lazy(
  () => import('../modules/pollination-slides/importance'),
);
const WhatIsPollination = lazy(
  () => import('../modules/pollination-slides/what-is'),
);
const CrossPollination = lazy(
  () => import('../modules/pollination-slides/cross-pollination'),
);
const SelfPollination = lazy(
  () => import('../modules/pollination-slides/self-pollination'),
);
const AbioticPollination = lazy(
  () => import('../modules/pollination-slides/abiotic-pollination'),
);
const BioticPollination = lazy(
  () => import('../modules/pollination-slides/biotic-pollination'),
);
const PollinationSyndromes = lazy(
  () => import('../modules/pollination-slides/syndromes'),
);

// Slides for Plant Structure module
const Roots = lazy(() => import('../modules/plantstructure-slides/roots'));
const Stems = lazy(() => import('../modules/plantstructure-slides/stems'));
const Xylem = lazy(
  () => import('../modules/plantstructure-slides/xylem-phloem'),
);
const Leaves = lazy(() => import('../modules/plantstructure-slides/leaves'));
const FlowerParts = lazy(
  () => import('../modules/plantstructure-slides/flower-parts'),
);
const FlowerFunction = lazy(
  () => import('../modules/plantstructure-slides/flower-function'),
);

// Slides for Classification module
const ClassyThings = lazy(
  () => import('../modules/classification-slides/classify-things'),
);
const PlantFamilies = lazy(
  () => import('../modules/classification-slides/plant-families'),
);
const WhatsName = lazy(
  () => import('../modules/classification-slides/whats-name'),
);
const RealPlant = lazy(
  () => import('../modules/classification-slides/real-plant'),
);
const Tools = lazy(() => import('../modules/classification-slides/tools'));
const InAction = lazy(
  () => import('../modules/classification-slides/in-action'),
);
// Slides for Adaptation module
const WhyAdapt = lazy(() => import('../modules/adaptation-slides/why-adapt'));
const HowAdapt = lazy(() => import('../modules/adaptation-slides/how-adapt'));
const Climate = lazy(() => import('../modules/adaptation-slides/climate'));
const Rainforest = lazy(
  () => import('../modules/adaptation-slides/rainforest'),
);
const Desert = lazy(() => import('../modules/adaptation-slides/desert'));
const Alpine = lazy(() => import('../modules/adaptation-slides/alpine'));
const AdaptingDynamic = lazy(
  () => import('../modules/adaptation-slides/adapting-dynamic'),
);

//Slides for Seeds module
const WhatSeed = lazy(() => import('../modules/seeds-slides/what'));
const WhereSeed = lazy(() => import('../modules/seeds-slides/where'));
const Variety = lazy(() => import('../modules/seeds-slides/variety'));
const Structure = lazy(() => import('../modules/seeds-slides/structure'));
const DispersalWhy = lazy(
  () => import('../modules/seeds-slides/dispersal-why'),
);
const DispersalHow = lazy(
  () => import('../modules/seeds-slides/dispersal-how'),
);
const SeedNeeds = lazy(() => import('../modules/seeds-slides/needs'));
const SeedBanks = lazy(() => import('../modules/seeds-slides/seed-banks'));

//Slides for Ecosystem module
const What = lazy(() => import('../modules/ecosystems-slides/what'));
const HowBig = lazy(() => import('../modules/ecosystems-slides/how-big'));
const Habitats = lazy(() => import('../modules/ecosystems-slides/habitats'));
const FoodChain = lazy(() => import('../modules/ecosystems-slides/food-chain'));
const Interactions = lazy(
  () => import('../modules/ecosystems-slides/interactions'),
);
const AustralianEcosystems = lazy(
  () => import('../modules/ecosystems-slides/australian-ecosystems'),
);
const Change = lazy(() => import('../modules/ecosystems-slides/change'));

export interface IExperienceLoaderProps {
  experience: string;
}

export default function ExperienceLoader(props: IExperienceLoaderProps) {
  const { experience } = props;
  const { setCurrentExperience, setShowPage } = useContext(LoaderContext);

  useEffect(() => {
    setCurrentExperience(experience);
  }, [experience, setCurrentExperience]);

  switch (experience) {
    case 'Plant Life Cycle':
      return (
        <Framed
          SliderConfig={[
            <Frame key={1}>
              <Lifecycles />
            </Frame>,
            <Frame key={2}>
              <Germination />
            </Frame>,
            <Frame key={3}>
              <Pollination setDone={undefined} />
            </Frame>,
            <Frame key={4}>
              <Seeds />
            </Frame>,
            <Frame key={5}>
              <Triggers />
            </Frame>,
            <Frame key={6}>
              <Order />
            </Frame>,
            <Frame key={7}>
              <Quiz
                title={'Attention budding botanists!'}
                instructions={
                  'Answer the questions to earn your life cycles badge.'
                }
                minimumCorrect={3}
                questions={[
                  {
                    question: 'What has a life cycle?',
                    image: undefined,
                    data: {
                      correctAnswer: 'All of them',
                      answers: ['Plants', 'Insects', 'Humans', 'All of them'],
                    },
                  },
                  {
                    question:
                      'Seed, seedling, plant, flowering plant and fruiting plant are all:',
                    image: undefined,
                    data: {
                      correctAnswer: 'Life cycle stages',
                      answers: [
                        'Life cycle stages',
                        'Life cycle processes',
                        'Dance moves',
                        'Plant names',
                      ],
                    },
                  },
                  {
                    question:
                      'Germination, growth, maturation, pollination, and seed dispersal are all:',
                    image: undefined,
                    data: {
                      correctAnswer: 'Life cycle processes',
                      answers: [
                        'Life cycle stages',
                        'Life cycle processes',
                        'Countries',
                        'Plant names',
                      ],
                    },
                  },
                  {
                    question:
                      'Which stage of a flowering plant life cycle is missing? <br/>seed -> ??? -> plant -> flowering plant -> fruiting plant',
                    image: undefined,
                    data: {
                      correctAnswer: 'Seedling',
                      answers: [
                        'Flying plant',
                        'Pollination',
                        'Seedling',
                        'Ageing plant',
                      ],
                    },
                  },
                  {
                    question: 'What do plants need to grow?',
                    image: undefined,
                    data: {
                      correctAnswer: 'All of them',
                      answers: [
                        'Water',
                        'The right temperature',
                        'Light',
                        'All of them',
                      ],
                    },
                  },
                  {
                    question: 'At what stage does a plant life cycle begin?',
                    image: undefined,
                    data: {
                      correctAnswer: 'Seed',
                      answers: ['Seedling', 'Soil', 'Fruiting plant', 'Seed'],
                    },
                  },
                  {
                    question:
                      'Cold stratification triggers some seeds to move from the seed to the seedling stage of the life cycle',
                    image: undefined,
                    data: {
                      correctAnswer: 'True',
                      answers: ['True', 'False'],
                    },
                  },
                  {
                    question:
                      'Many Banksia plants need what environmental trigger to release their seed?',
                    image: undefined,
                    data: {
                      correctAnswer: 'Fire',
                      answers: ['Cold', 'Fire', 'Light', 'Bush Rats'],
                    },
                  },
                  {
                    question:
                      'Which stage of a flowering plant life cycle is missing? | seed -> seedling -> ??? -> fruiting plant',
                    image: undefined,
                    data: {
                      correctAnswer: 'Flowering plant',
                      answers: [
                        'Flowering plant',
                        'Partying plant',
                        'Seed dispersal',
                        'Watering',
                      ],
                    },
                  },
                  {
                    question:
                      'Some plants in a rainforest can pause at the seedling stage of the life cycle until a gap in the canopy means that light can hit the seedling. This triggers the seedling to change to the next stage of the life cycle.',
                    image: undefined,
                    data: {
                      correctAnswer: 'True',
                      answers: ['True', 'False'],
                    },
                  },
                  {
                    question:
                      'Pollination is not a life cycle stage; it is a process that moves a plant from the flowering plant to the fruiting plant stage of its life cycle.',
                    image: undefined,
                    data: {
                      correctAnswer: 'True',
                      answers: ['True', 'False'],
                    },
                  },
                ]}
                setDone={() => setShowPage('outro')}
              />
            </Frame>,
          ]}
        />
      );
    case 'Plant Structure':
      return (
        <Framed
          SliderConfig={[
            <Frame key={1}>
              <Roots />
            </Frame>,
            <Frame key={2}>
              <Stems />
            </Frame>,
            <Frame key={3}>
              <Xylem />
            </Frame>,
            <Frame key={4}>
              <Leaves />
            </Frame>,
            <Frame key={5}>
              <FlowerParts />
            </Frame>,
            <Frame key={6}>
              <FlowerFunction setDone={undefined} />
            </Frame>,
            <Frame key={7}>
              <Quiz
                title={'Attention budding botanists!'}
                instructions={
                  'Answer the questions to earn your Plant Structure badge.'
                }
                minimumCorrect={3}
                questions={[
                  {
                    question: 'Which is not part of a plant?',
                    image: undefined,
                    data: {
                      correctAnswer: 'Dirt',
                      answers: ['Stem', 'Dirt', 'Flower', 'Root'],
                    },
                  },
                  {
                    question: 'The function of a root is to',
                    image: undefined,
                    data: {
                      correctAnswer: 'All of these',
                      answers: [
                        'Keep a plant in place',
                        'Move water ',
                        'Move nutrients',
                        'All of these',
                      ],
                    },
                  },
                  {
                    question:
                      'Most flowering plants have the same parts: roots, stem, flowers and what?',
                    image: undefined,
                    data: {
                      correctAnswer: 'Leaves',
                      answers: ['Leaves', 'Rocks', 'Beetles', 'Spikes'],
                    },
                  },
                  {
                    question: 'Stems are important to hold up:',
                    image: undefined,
                    data: {
                      correctAnswer: 'All of these',
                      answers: ['Flowers', 'Leaves', 'Fruits', 'All of these'],
                    },
                  },
                  {
                    question: 'The trunk of a tree is a type of stem.',
                    image: undefined,
                    data: {
                      correctAnswer: 'True',
                      answers: ['True', 'False'],
                    },
                  },
                  {
                    question:
                      'Plants use what to make food through photosynthesis?',
                    image: undefined,
                    data: {
                      correctAnswer: 'Sunlight',
                      answers: ['Lollies', 'Sunlight', 'Worms', 'Soil'],
                    },
                  },
                  {
                    question:
                      'Petals help to attract animals, like insects, for what reason?',
                    image: undefined,
                    data: {
                      correctAnswer: 'Pollination',
                      answers: [
                        'Dancing',
                        'Pollination',
                        'Photosynthesis',
                        'Posing for photos',
                      ],
                    },
                  },
                  {
                    question:
                      'The stigma is the female part of the flower that receives the pollen.',
                    image: undefined,
                    data: {
                      correctAnswer: 'True',
                      answers: ['True', 'False'],
                    },
                  },

                  {
                    question:
                      'What do some leaves have that help to stop animals eating them?',
                    image: undefined,
                    data: {
                      correctAnswer: 'Prickles',
                      answers: [
                        'Claws',
                        'Quicksand',
                        'Prickles',
                        'Invisible mode',
                      ],
                    },
                  },
                  {
                    question:
                      'The anther is the male part of the flower that contains pollen.',
                    image: undefined,
                    data: {
                      correctAnswer: 'True',
                      answers: ['True', 'False'],
                    },
                  },
                ]}
                setDone={() => setShowPage('outro')}
              />
            </Frame>,
          ]}
        />
      );
    case 'Pollination':
      return (
        <Framed
          SliderConfig={[
            <Frame key={1}>
              <Importance setDone={undefined} />
            </Frame>,
            <Frame key={2}>
              <WhatIsPollination setDone={undefined} />
            </Frame>,
            <Frame key={3}>
              <CrossPollination />
            </Frame>,
            <Frame key={4}>
              <SelfPollination />
            </Frame>,
            <Frame key={5}>
              <AbioticPollination />
            </Frame>,
            <Frame key={6}>
              <BioticPollination />
            </Frame>,
            <Frame key={7}>
              <PollinationSyndromes setDone={undefined} />
            </Frame>,
            <Frame key={8}>
              <Quiz
                title="Become a | pollination master!"
                instructions="Answer the questions to earn your pollination badge."
                minimumCorrect={10}
                questions={[
                  {
                    question:
                      ' If a flower is pollinated, it can develop fruit with a what?',
                    image: undefined,
                    data: {
                      answers: ['Insects ', 'Seed', 'Petals ', 'Anthers '],
                      correctAnswer: 'Seed',
                    },
                  },
                  {
                    question:
                      'Without pollination, many flowering plant species would become:',
                    image: undefined,
                    data: {
                      answers: [
                        'Dinosaurs',
                        'Water plants',
                        'Larger ',
                        'Extinct',
                      ],
                      correctAnswer: 'Extinct',
                    },
                  },
                  {
                    question:
                      'Without pollination there would be less of what?',
                    image: undefined,
                    data: {
                      answers: [
                        'Food',
                        'Habitat',
                        'Biodiversity',
                        'All of the these',
                      ],
                      correctAnswer: 'All of the these',
                    },
                  },
                  {
                    question:
                      'Pollination is the transfer of pollen from the male anther to the female stigma of a flower.',
                    image: undefined,
                    data: {
                      answers: ['True', 'False'],
                      correctAnswer: 'True',
                    },
                  },
                  {
                    question:
                      'After pollination, what part of the flower changes into a fruit with seed?',
                    image: undefined,
                    data: {
                      answers: ['Ovary', 'Ovules', 'Petals ', 'Stigma '],
                      correctAnswer: 'Ovary',
                    },
                  },
                  {
                    question: 'Fruit can be:',
                    image: undefined,
                    data: {
                      answers: [
                        'Soft',
                        'Woody',
                        'Weird shaped',
                        'All of the these',
                      ],
                      correctAnswer: 'All of the these',
                    },
                  },
                  {
                    question:
                      'Cross-pollination is pollen moving between flowers on different plants.',
                    image: undefined,
                    data: {
                      answers: ['True', 'False'],
                      correctAnswer: 'True',
                    },
                  },
                  {
                    question: 'Which part of the flower makes the pollen?',
                    image: undefined,
                    data: {
                      answers: ['Anther', 'Stigma', 'Petal', 'Leaf'],
                      correctAnswer: 'Anther',
                    },
                  },
                  {
                    question:
                      'A pollinaton syndrome for a flower that is pollinated by bats might be:',
                    image: undefined,
                    data: {
                      answers: [
                        'Night opening',
                        'Night smells',
                        'White flowers',
                        'All of the these',
                      ],
                      correctAnswer: 'All of the these',
                    },
                  },
                  {
                    question:
                      'Pollinators can be living, like insects, bats or birds or non-living, like wind or water.',
                    image: undefined,
                    data: {
                      answers: ['True', 'False'],
                      correctAnswer: 'True',
                    },
                  },
                ]}
                setDone={() => setShowPage('outro')}
              />
            </Frame>,
          ]}
        />
      );
    case 'Classification':
      return (
        <Framed
          SliderConfig={[
            <Frame key={1}>
              <ClassyThings />
            </Frame>,
            <Frame key={2}>
              <PlantFamilies />
            </Frame>,
            <Frame key={3}>
              <WhatsName />
            </Frame>,
            <Frame key={4}>
              <RealPlant />
            </Frame>,
            <Frame key={5}>
              <Tools />
            </Frame>,
            <Frame key={6}>
              <InAction />
            </Frame>,
            <Frame key={7}>
              <Quiz
                title="Become a master| of classification!"
                instructions="These questions are placeholders, we still need copy here!"
                minimumCorrect={3}
                questions={[
                  {
                    question:
                      'What aspects of a leaf can change to suit its surrounding environment?',
                    image: undefined,
                    data: {
                      answers: ['Shape', 'Colour', 'Angle', 'All options'],
                      correctAnswer: 'All options',
                    },
                  },
                  {
                    question:
                      'Thin, curled leaves are better suited to arid (desert) conditions.',
                    image: undefined,
                    data: {
                      answers: ['True', 'False'],
                      correctAnswer: 'True',
                    },
                  },
                  {
                    question:
                      'What characteristic of roots is the most beneficial to a Rainforest environment?',
                    image: undefined,
                    data: {
                      answers: [
                        'Deep roots',
                        'Shallow roots',
                        'Absorbent roots',
                        'Long roots',
                      ],
                      correctAnswer: 'Shallow roots',
                    },
                  },
                ]}
                setDone={() => setShowPage('outro')}
              />
            </Frame>,
          ]}
        />
      );
    case 'Adaptation':
      return (
        <Framed
          SliderConfig={[
            <Frame key={1}>
              <WhyAdapt />
            </Frame>,
            <Frame key={2}>
              <HowAdapt />
            </Frame>,
            <Frame key={3}>
              <Climate />
            </Frame>,
            <Frame key={4}>
              <Rainforest />
            </Frame>,
            <Frame key={5}>
              <Desert />
            </Frame>,
            <Frame key={6}>
              <Alpine />
            </Frame>,
            <Frame key={7}>
              <AdaptingDynamic />
            </Frame>,
            <Frame key={8}>
              <Quiz
                title="Become a master| of adaptation!"
                instructions="These questions are placeholders, we still need copy here!"
                minimumCorrect={3}
                questions={[
                  {
                    question:
                      'What aspects of a leaf can change to suit its surrounding environment?',
                    image: undefined,
                    data: {
                      answers: ['Shape', 'Colour', 'Angle', 'All options'],
                      correctAnswer: 'All options',
                    },
                  },
                  {
                    question:
                      'Thin, curled leaves are better suited to arid (desert) conditions.',
                    image: undefined,
                    data: {
                      answers: ['True', 'False'],
                      correctAnswer: 'True',
                    },
                  },
                  {
                    question:
                      'What characteristic of roots is the most beneficial to a Rainforest environment?',
                    image: undefined,
                    data: {
                      answers: [
                        'Deep roots',
                        'Shallow roots',
                        'Absorbent roots',
                        'Long roots',
                      ],
                      correctAnswer: 'Shallow roots',
                    },
                  },
                ]}
                setDone={() => setShowPage('outro')}
              />
            </Frame>,
          ]}
        />
      );
    case 'Seeds':
      return (
        <Framed
          SliderConfig={[
            <Frame key={1}>
              <WhatSeed />
            </Frame>,
            <Frame key={2}>
              <WhereSeed />
            </Frame>,
            <Frame key={3}>
              <Variety />
            </Frame>,
            <Frame key={4}>
              <Structure />
            </Frame>,
            <Frame key={5}>
              <DispersalWhy />
            </Frame>,
            <Frame key={6}>
              <DispersalHow />
            </Frame>,
            <Frame key={7}>
              <SeedNeeds />
            </Frame>,
            <Frame key={8}>
              <SeedBanks />
            </Frame>,
            <Frame key={9}>
              <Quiz
                title="Attention|budding botanists!"
                instructions="Answer the questions to earn your Seeds badge."
                minimumCorrect={10}
                questions={[
                  {
                    question: "A seed's parts are: ",
                    image: undefined,
                    data: {
                      answers: [
                        'A plant embryo',
                        'A seed coat',
                        'Plant food',
                        'All of these',
                      ],
                      correctAnswer: 'All of these',
                    },
                  },
                  {
                    question:
                      'Seeds need water, nutrients, the right temperature, and the right music to germinate and grow.',
                    image: undefined,
                    data: {
                      answers: ['True', 'False'],
                      correctAnswer: 'False',
                    },
                  },
                  {
                    question:
                      'Seeds need water, nutrients, and the right temperature to germinate and grow.',
                    image: undefined,
                    data: {
                      answers: ['True', 'False'],
                      correctAnswer: 'True',
                    },
                  },
                  {
                    question:
                      'Seed dispersal is when a seed is moved away from the parent plant.',
                    image: undefined,
                    data: {
                      answers: ['True', 'False'],
                      correctAnswer: 'True',
                    },
                  },
                  {
                    question:
                      'After pollination, which part of the flower changes into a fruit with seed inside?',
                    image: undefined,
                    data: {
                      answers: ['Ovary', 'Ovules', 'Petals ', 'Stigma'],
                      correctAnswer: 'Ovary',
                    },
                  },
                  {
                    question:
                      'After pollination, what part of the flower changes into a seed?',
                    image: undefined,
                    data: {
                      answers: ['Ovary', 'Ovules', 'Petals ', 'Stigma'],
                      correctAnswer: 'Ovules',
                    },
                  },
                  {
                    question: 'Which is not a way that seeds are dispersed?',
                    image: undefined,
                    data: {
                      answers: ['Wind', 'Bird poo', 'Pogo stick', 'Animal fur'],
                      correctAnswer: 'Pogo stick',
                    },
                  },
                  {
                    question: 'Seeds take in water through their:',
                    image: undefined,
                    data: {
                      answers: ['Seed coat', 'Shoot', 'Leaves', 'Embryo'],
                      correctAnswer: 'Seed coat',
                    },
                  },
                  {
                    question:
                      'The shoot of a planted seed will always grow in which direction?',
                    image: undefined,
                    data: {
                      answers: ['Up', 'Left', 'Down', 'Right'],
                      correctAnswer: 'Up',
                    },
                  },
                  {
                    question:
                      'A seed bank is important because it stores seed as a back-up in case the plant species goes extinct in the wild.',
                    image: undefined,
                    data: {
                      answers: ['True', 'False'],
                      correctAnswer: 'True',
                    },
                  },
                ]}
                setDone={() => setShowPage('outro')}
              />
            </Frame>,
          ]}
        />
      );
    case 'Ecosystems':
      return (
        <Framed
          SliderConfig={[
            <Frame key={1}>
              <What />
            </Frame>,
            <Frame key={2}>
              <HowBig />
            </Frame>,
            <Frame key={3}>
              <Habitats />
            </Frame>,
            <Frame key={4}>
              <FoodChain />
            </Frame>,
            <Frame key={5}>
              <Interactions />
            </Frame>,
            <Frame key={6}>
              <AustralianEcosystems />
            </Frame>,
            <Frame key={7}>
              <Change />
            </Frame>,
            <Frame key={8}>
              <Quiz
                title="Let's learn|about seeds!"
                instructions="These questions are placeholders, we still need copy here!"
                minimumCorrect={3}
                questions={[
                  {
                    question: 'A seeds parts are: ',
                    image: undefined,
                    data: {
                      answers: [
                        'A plant embryo',
                        'A plant embryo',
                        'Plant food',
                        'All of the above',
                      ],
                      correctAnswer: 'All of the above',
                    },
                  },
                  {
                    question:
                      'Seeds need water, nutrients, the right temperature, and the right music to germinate and grow.',
                    image: undefined,
                    data: {
                      answers: ['True', 'False'],
                      correctAnswer: 'False',
                    },
                  },
                  {
                    question:
                      'Seeds need water, nutrients, and the right temperature to germinate and grow.',
                    image: undefined,
                    data: {
                      answers: ['True', 'False'],
                      correctAnswer: 'True',
                    },
                  },
                  {
                    question:
                      'Seed dispersal is when a seed moves away from the parent plant.',
                    image: undefined,
                    data: {
                      answers: ['True', 'False'],
                      correctAnswer: 'True',
                    },
                  },
                  {
                    question:
                      'After pollination, which part of the flower changes into a fruit with seed inside?',
                    image: undefined,
                    data: {
                      answers: ['Ovary', 'Ovules', 'Petals ', 'Stigma'],
                      correctAnswer: 'Ovary',
                    },
                  },
                  {
                    question:
                      'After pollination, what part of the flower changes into a seed?',
                    image: undefined,
                    data: {
                      answers: ['Ovary', 'Ovules', 'Petals ', 'Stigma'],
                      correctAnswer: 'Ovules',
                    },
                  },
                  {
                    question: 'Which is not a way that seeds are dispersed?',
                    image: undefined,
                    data: {
                      answers: ['Wind', 'Bird poo', 'Pogo stick', 'Animal fur'],
                      correctAnswer: 'Pogo stick',
                    },
                  },
                  {
                    question: 'Seeds take in water through their:',
                    image: undefined,
                    data: {
                      answers: ['Seed coat', 'Shoot', 'Leaves', 'Embryo'],
                      correctAnswer: 'Seed coat',
                    },
                  },
                  {
                    question:
                      'The shoot of a planted seed will always grow in which direction?',
                    image: undefined,
                    data: {
                      answers: ['Up', 'Left', 'Down', 'Right'],
                      correctAnswer: 'Up',
                    },
                  },
                  {
                    question:
                      'A seed bank is important because it stores seed as a back-up in case the plant species goes extinct in the wild.',
                    image: undefined,
                    data: {
                      answers: ['True', 'False'],
                      correctAnswer: 'True',
                    },
                  },
                ]}
                setDone={() => setShowPage('outro')}
              />
            </Frame>,
          ]}
        />
      );
    default:
      return <>Experience Not Found</>;
  }
}
