import React from 'react';
import AtomDrupalContent from '../../atom/drupal-content';

export default function InteractiveOutro({
  summary,
  image,
  buttonText,
  badge,
}) {
  return (
    <section className="tw-flex tw-h-full tw-w-[100vw] tw-bg-anbg-orange tw-py-12 tw-px-4 xl:tw-ml-[235px] xl:tw-w-[calc(100vw-235px)] xl:tw-py-24 xl:tw-px-12 2xl:tw-py-32">
      <div className="tw-mx-auto tw-w-full tw-max-w-screen-2xl tw-items-center tw-justify-center tw-text-anbg-cream md:tw-flex md:tw-space-x-10 xl:tw-space-x-20 2xl:tw-space-x-32">
        <div className="tw-flex tw-flex-col md:tw-w-1/2">
          <AtomDrupalContent
            className="prose-h2:textLarge xl:prose-h2:textLarge !tw-text-anbg-cream prose-headings:!tw-text-anbg-cream prose-h2:tw-text-anbg-cream"
            content={summary}
          />
          {badge && (
            <div className="tw-mt-10 tw-flex tw-justify-start">
              <a href={badge} download className="lh-btn lh-btn-yellow">
                {buttonText || 'Download badge'}
              </a>
            </div>
          )}
        </div>
        <div className="md:w-1/2">
          {image && <img src={image} className="tw-max-w-full" alt="" />}
        </div>
      </div>
    </section>
  );
}
