import React from 'react';
import { Flex } from '../Flex';
import { ControlButton } from './ControlButton';
import { ControlLabel } from './ControlLabel';
import { useSlideContext } from '../../hooks/useSlideContext';

const styles = {
  background: 'rgb(252, 186, 17)',
  height: '75px',
  flexGrow: '1',
};

export function Controls() {
  const { slideName, nextSlide, prevSlide, currentSlideIndex, slideCount } =
    useSlideContext();

  return (
    <Flex flexStyles={styles}>
      {currentSlideIndex > 0 ? (
        <ControlButton label={'Back'} onClick={prevSlide} />
      ) : (
        <span className="tw-w-20" aria-hidden></span>
      )}

      <ControlLabel label={slideName ?? 'Unknown Slide'} />

      {currentSlideIndex >= slideCount - 1 ? (
        <span className="tw-w-20" aria-hidden></span>
      ) : (
        <ControlButton label={'Forward'} onClick={nextSlide} reverse />
      )}
    </Flex>
  );
}
