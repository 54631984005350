import React, { ReactElement, lazy, Suspense } from 'react';
import { IFrameProps } from './components/Frames/Frame';
import { SliderProvider } from './providers/SliderProvider';
import { Flex } from './components/Flex';
import { Controls } from './components/Controls/Controls';
import LoadingAnimation from '../atom/LoadingAnimation';
import Slider from './components/Slider/Slider';
const LazySlider = lazy(() => import('./components/Slider/Slider'));
export interface IFramedProps {
  SliderConfig: ReactElement<IFrameProps>[];
}

export function Framed(props: IFramedProps) {
  const { SliderConfig } = props;
  const isSSR = typeof window === 'undefined';

  return (
    <SliderProvider slides={SliderConfig}>
      <div className="tw-absolute -tw-top-1/2" aria-hidden id="top-section" />
      <Flex
        className="blueBg xl:tw-ml-[235px]"
        flexDirection={'column'}
        flexStyles={{ height: '100%' }}
      >
        <Flex>
          <Flex flexGrow={1}>
            <Controls slideCount={SliderConfig.length} />
          </Flex>
        </Flex>
        <Flex flexGrow={1} className="tw-py-16">
          {!isSSR && (
            <Suspense fallback={<LoadingAnimation />}>
              <LazySlider />
            </Suspense>
          )}
          {isSSR && <Slider />}
        </Flex>
        <Flex>
          <Flex flexGrow={1}>
            <Controls slideCount={SliderConfig.length} />
          </Flex>
        </Flex>
      </Flex>
    </SliderProvider>
  );
}
