import { useCallback, useEffect, useState } from 'react';
import { IFrameProps } from '../components/Frames/Frame';

export function useSliderNavigation(
  slides: React.ReactElement<IFrameProps>[],
  initialSlide = 0,
) {
  const [currentSlide, setCurrentSlide] = useState<
    React.ReactElement<IFrameProps>
  >(slides[initialSlide]);

  const [currentSlideIndex, setCurrentSlideIndex] = useState(initialSlide);

  const [slideName, setSlideName] = useState<string | undefined>(undefined);

  const nextSlide = useCallback(() => {
    setCurrentSlideIndex((prevState: number) =>
      prevState >= slides.length - 1 ? 0 : prevState + 1,
    );
  }, [setCurrentSlideIndex, slides]);

  const prevSlide = useCallback(() => {
    setCurrentSlideIndex((prevState: number) =>
      prevState === 0 ? 0 : prevState - 1,
    );
  }, [setCurrentSlideIndex]);

  const slideTo = useCallback(
    (index: number) => {
      setCurrentSlideIndex(index);
    },
    [setCurrentSlideIndex],
  );

  useEffect(() => {
    setSlideName(slides[currentSlideIndex].props.name);
    setCurrentSlide(slides[currentSlideIndex]);
  }, [currentSlideIndex, slides]);

  return {
    currentSlideIndex,
    nextSlide,
    prevSlide,
    slideTo,
    slideName,
    slideCount: slides.length,
    currentSlide,
  };
}
