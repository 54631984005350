import React from 'react';
import ScrollTo from 'react-scroll-into-view';

export type IControlButtonProps = {
  onClick?: () => void;
  label: string;
  reverse?: boolean; // This prop will determine which class to apply
};

export function ControlButton(props: IControlButtonProps) {
  const { onClick, label, reverse } = props;

  // Use a conditional operator to apply different classes based on `reverse`
  const buttonClassName = `
    tw-flex 
    tw-items-center 
    tw-justify-center 
    tw-p-6 
    tw-text-xl 
    tw-transition-color
    tw-duration-300
    tw-ease-in-out
    tw-font-bold 
    ${
      reverse
        ? 'tw-text-white tw-bg-anbg-dark-green hover:tw-text-anbg-yellow'
        : 'tw-text-anbg-dark-green hover:tw-bg-anbg-dark-green hover:tw-text-white'
    }
  `;

  return (
    <>
      <ScrollTo
        selector="#top-section"
        onClick={onClick}
        scrollOptions={{ block: 'start' }}
        className={buttonClassName.trim()} // Apply the class names here
      >
        <button type="button" className="tw-cursor-pointer">
          {label}
        </button>
      </ScrollTo>
    </>
  );
}
